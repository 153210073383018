<template>
  <div class="recoverit">
    <el-card class="filter-card">
      <!--数据筛选表单-->
      <el-form  @submit.native.prevent="onSearch" ref="form" :model="form" label-width="68px">
        <el-form-item label="产品PID">
          <el-input v-model="pid" ></el-input>
          <el-button
            type="primary"
            :disabled="loading"
            @click="onSearch">查询</el-button>
        </el-form-item>
      </el-form>
    </el-card>

    <!-- 配置表单 -->
    <el-card class="box-card">
      <div slot="header" class="searchfix">
        <span>根据筛选条件共查询到 {{data.total}} 条结果:</span>
        <div class="btn">
        <el-button
          type="primary"
          icon="el-icon-circle-plus-outline"
          @click="onHandleAdd"
        >添加</el-button>
        <upload-excel-comp :on-success="handleSuccess" :before-upload="beforeUpload" style="margin-left: 10px;  display: inline-block;"/></div>
      </div>

      <!--数据列表-->
      <el-table
        :data="data.results"
        stripe
        border
        highlight-current-row
        style="width: 100%"
        class="list-table"
        size="mini"
        v-loading="loading"
      >
        <el-table-column
          prop="Pid"
          label="产品PID"
          sortable
          width="120"
        >
        </el-table-column>
        <el-table-column
          prop="Preview.PreviewLimit"
          align="center"
          label="预览限制类型">
          <template slot-scope="scope">
            {{ scope.row.preview.PreviewLimit === 0 ? "不限" : "大小" }}
          </template>
        </el-table-column>
        <el-table-column
          prop="Preview.MaxSize"
          align="center"
          label="预览文件大小(MB)">
          <template slot-scope="scope">
            {{ scope.row.preview.PreviewLimit === 0 ? "/" : scope.row.preview.MaxSize}}
          </template>
        </el-table-column>
        <el-table-column
          prop="Preview.PreviewMaskSwitch"
          align="center"
          label="预览蒙版开关">
          <template slot-scope="scope">
            {{ scope.row.preview.PreviewMaskSwitch === 0 ? "关闭" : "开启"}}
          </template>
        </el-table-column>
        
        <el-table-column
          prop="freelimit.size"
          align="center"
          label="免费额度 Size">
          <template slot-scope="scope">
            {{ scope.row.free_limit.Size === 0 ? 30 : scope.row.free_limit.Size}}
          </template>
        </el-table-column>

        <el-table-column
          prop="properties.FirstMonthFreeSize"
          align="center"
          label="Free版本首月免费Size">
          <template slot-scope="scope">
            {{ scope.row.properties.FirstMonthFreeSize === 0 ? 500 : scope.row.properties.FirstMonthFreeSize}}
          </template>
        </el-table-column>

        <el-table-column
          prop="properties.MonthlyFreeSize"
          align="center"
          label="Free版本每月免费Size">
          <template slot-scope="scope">
            {{ scope.row.properties.MonthlyFreeSize === 0 ? 200 : scope.row.properties.MonthlyFreeSize}}
          </template>
        </el-table-column>

        <el-table-column
          prop="properties.UsageScenarioSwitch"
          align="center"
          label="场景收集开关">
          <template slot-scope="scope">
            {{ scope.row.properties.UsageScenarioSwitch === 0 ? "关闭" : "开启"}}
          </template>
        </el-table-column>
        <el-table-column
          prop="properties.SDCardGuidingAdvancedRecoverySwitch"
          align="center"
          label="SD卡引导高级恢复">
          <template slot-scope="scope">
            {{ scope.row.properties.SDCardGuidingAdvancedRecoverySwitch === 0 ? "关闭" : scope.row.properties.SDCardGuidingAdvancedRecoverySwitch === 2 ? "所有" : "SD卡功能" }}
          </template>
        </el-table-column> 
        <el-table-column
          prop="properties.TrustPilotRateGuidingSwitch"
          align="center"
          label="TP评分开关">
          <template slot-scope="scope">
            {{ scope.row.properties.TrustPilotRateGuidingSwitch === 0 ? "关闭" : "开启"}}
          </template>
        </el-table-column> 
        <el-table-column
          prop="properties.NewUserGuidingSwitch"
          align="center"
          label="新手引导开关">
          <template slot-scope="scope">
            {{ scope.row.properties.NewUserGuidingSwitch === 0 ? "关闭" : scope.row.properties.NewUserGuidingSwitch === 2 ? "开且不可关闭" : "开启" }}
          </template>
        </el-table-column> 
        <el-table-column
          prop="Preview.LogSwitch"
          align="center"
          label="日志开关">
          <template slot-scope="scope">
            {{ scope.row.preview.LogSwitch === 0 ? "关闭" : "开启"}}
          </template>
        </el-table-column>
        <el-table-column
          prop="Preview.DiscountSwitch"
          align="center"
          label="折扣开关">
          <template slot-scope="scope">
            {{ scope.row.properties.DiscountSwitch === 0 ? "关闭" : "开启"}}
          </template>
        </el-table-column>        
        <el-table-column
          prop="Preview.WeekDiscountSwitch"
          align="center"
          label="周折扣开关">
          <template slot-scope="scope">
            {{ scope.row.properties.WeekDiscountSwitch === 0 ? "关闭" : scope.row.properties.WeekDiscountSwitch === 2 ? "所有" : "功能" }}
          </template>
        </el-table-column>
        <el-table-column
          prop="Preview.CompetitorDiscountSwitch"
          align="center"
          label="竞品折扣开关">
          <template slot-scope="scope">
            {{ scope.row.properties.CompetitorDiscountSwitch === 0 ? "关闭" : scope.row.properties.CompetitorDiscountSwitch === 2 ? "所有" : "功能" }}
          </template>
        </el-table-column>
        <el-table-column
          prop="Preview.NonCompetitorDiscountSwitch"
          align="center"
          label="非竞品折扣开关">
          <template slot-scope="scope">
            {{ scope.row.properties.NonCompetitorDiscountSwitch === 0 ? "关闭" : scope.row.properties.NonCompetitorDiscountSwitch === 2 ? "所有" : "功能" }}
          </template>
        </el-table-column>  
        <el-table-column
          prop="Preview.BuySwitch"
          align="center"
          label="购买开关">
          <template slot-scope="scope">
            {{ scope.row.properties.BuySwitch === 0 ? "关闭" : "开启"}}
          </template>
        </el-table-column>   
        <el-table-column
          prop="Preview.MonResidentSwitch"
          align="center"
          label="监控常驻开关">
          <template slot-scope="scope">
            {{ scope.row.properties.MonResidentSwitch === 0 ? "关闭" : "开启"}}
          </template>
        </el-table-column> 
        <el-table-column
          prop="Preview.SceneCollectionSwitch"
          align="center"
          label="用户场景收集开关">
          <template slot-scope="scope">
            {{ scope.row.properties.SceneCollectionSwitch === 0 ? "关闭" : "开启"}}
          </template>
        </el-table-column> 
        <el-table-column
          prop="Preview.WeekDiscountSwitch"
          align="center"
          label="竞品信息收集开关">
          <template slot-scope="scope">
            {{ scope.row.software.RsSwitch ? "开启" : "关闭" }}
          </template>
        </el-table-column> 
        <el-table-column
          prop="properties.FileTypeSwitch"
          align="center"
          label="FileType开关">
          <template slot-scope="scope">
            {{ scope.row.properties.FileTypeSwitch ? "开启" : "关闭" }}
          </template>
        </el-table-column> 
        <el-table-column width="100"
          prop="create_by"
          :formatter="formatterEmpty"
          align="center"
          label="创建人">
        </el-table-column> 
        <el-table-column width="100"
          prop="update_by"
          :formatter="formatterEmpty"
          align="center"
          label="更新人">
        </el-table-column>
        <el-table-column
          prop="update_time"
          :formatter="formatterDateTime"
          align="center"
          label="更新时间">
        </el-table-column>

        <el-table-column label="操作" width="120" align="center">
          <template slot-scope="scope">
            <el-button
              size="mini"
              type="primary"
              circle
              icon="el-icon-edit"
              @click="onEditProductCfg(scope.row)"
            ></el-button>
            <el-button
              size="mini"
              type="danger"
              circle
              icon="el-icon-delete"
              @click="onDeleteProductCfg(scope.row.Pid)"
            ></el-button>
          </template>
        </el-table-column>
      </el-table>

      <!--列表分页-->
      <el-pagination
        background
        layout="prev, pager, next"
        :total="data.total"
        :page-size="pageSize"
        :disabled = "loading"
        :current-page.sync="page"
        @current-change="onCurrentChange" />
    </el-card>


    <!-- 添加用户对话框 -->
    <el-dialog :title="title" custom-class="recoverit-product" :close-on-click-modal="false" :visible.sync="dialogFormVisible" width="520px" append-to-body>
      <el-form ref="addFormRef" :model="addForm" :rules="addFormRules" label-width="120px">
        <el-form-item label="用户PID: " prop="newPid">
          <el-input :disabled="operType" onkeyup="this.value = this.value.replace(/[^\d]/g,'');" maxlength="8" v-model="addForm.newPid" placeholder="请输入用户PID"/>
        </el-form-item>

        <el-form-item label="预览文件大小: " class="preview-file">
          <el-radio-group v-model="addForm.previewLimit">
            <el-form-item  prop="Limit" style="margin-bottom: 10px">
              <el-radio :label="0" >PreviewLimit&nbsp&nbsp<el-input v-model="addForm.previewLimit == 0 ? '/ ': '/'" placeholder=""  class="el-input" /></el-radio>
            </el-form-item>
            <el-form-item  prop="maxSize">
              <el-radio :label="1" >MaxSize&nbsp&nbsp&nbsp&nbsp&nbsp&nbsp&nbsp&nbsp<el-input-number  :min="1" v-model="addForm.maxSize" controls-position="right" class="el-input" /></el-radio>
            </el-form-item>
          </el-radio-group>
        </el-form-item>

        <el-form-item label="免费额度Size:" class="free-limit" style="display: flex; align-items: right;">
          <el-input-number :min="1" v-model="addForm.size" controls-position="right" class="el-input" />
        </el-form-item>
        <el-form-item label="Free版本首月免费Size:" class="free-limit" style="display: flex; align-items: right;">
          <el-input-number :min="1" v-model="addForm.FirstMonthFreeSize" controls-position="right" class="el-input" />
        </el-form-item>
        <el-form-item label="Free版本每月免费Size:" class="free-limit" style="display: flex; align-items: right;">
          <el-input-number :min="1" v-model="addForm.MonthlyFreeSize" controls-position="right" class="el-input" />
        </el-form-item>

        <el-form-item label="预览蒙版开关: " prop="previewMaskSwitch">
          <el-radio-group v-model="addForm.previewMaskSwitch">
            <el-radio :label="0">关闭</el-radio>
            <el-radio :label="1">开启</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item label="场景收集开关: " prop="">
          <el-radio-group v-model="addForm.usageScenarioSwitch">
            <el-radio :label="0">关闭</el-radio>
            <el-radio :label="1">开启</el-radio>
          </el-radio-group>
        </el-form-item>  
        <el-form-item label="SD卡引导高级恢复: " prop="SDCardGuidingAdvancedRecoverySwitch">
          <el-radio-group v-model="addForm.SDCardGuidingAdvancedRecoverySwitch">
            <el-radio :label="0">关闭</el-radio>
            <el-radio :label="1">功能</el-radio>
            <el-radio :label="2">所有</el-radio>
          </el-radio-group>
        </el-form-item> 
        <el-form-item label="日志开关: " prop="logSwitch">
          <el-radio-group v-model="addForm.logSwitch">
            <el-radio :label="0">关闭</el-radio>
            <el-radio :label="1">开启</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item label="折扣开关: " prop="discountSwitch">
          <el-radio-group v-model="addForm.discountSwitch">
            <el-radio :label="0">关闭</el-radio>
            <el-radio :label="1">开启</el-radio>
          </el-radio-group>
        </el-form-item>  
        <el-form-item label="周折扣开关: " prop="WeekDiscountSwitch">
          <el-radio-group v-model="addForm.WeekDiscountSwitch">
            <el-radio :label="0">关闭</el-radio>
            <el-radio :label="1">功能</el-radio>
            <el-radio :label="2">所有</el-radio>
          </el-radio-group>
        </el-form-item> 
        <el-form-item label="竞品折扣开关: " prop="CompetitorDiscountSwitch">
          <el-radio-group v-model="addForm.CompetitorDiscountSwitch">
            <el-radio :label="0">关闭</el-radio>
            <el-radio :label="1">功能</el-radio>
            <el-radio :label="2">所有</el-radio>
          </el-radio-group>
        </el-form-item> 
        <el-form-item label="非竞品折扣开关: " prop="NonCompetitorDiscountSwitch">
          <el-radio-group v-model="addForm.NonCompetitorDiscountSwitch">
            <el-radio :label="0">关闭</el-radio>
            <el-radio :label="1">功能</el-radio>
            <el-radio :label="2">所有</el-radio>
          </el-radio-group>
        </el-form-item> 
        <el-form-item label="购买开关: " prop="BuySwitch">
          <el-radio-group v-model="addForm.BuySwitch">
            <el-radio :label="0">关闭</el-radio>
            <el-radio :label="1">开启</el-radio>
          </el-radio-group>
        </el-form-item> 
        <el-form-item label="监控常驻: " prop="MonResidentSwitch">
          <el-radio-group v-model="addForm.MonResidentSwitch">
            <el-radio :label="0">关闭</el-radio>
            <el-radio :label="1">开启</el-radio>
          </el-radio-group>
        </el-form-item>  
        <el-form-item label="用户场景收集: " prop="SceneCollectionSwitch">
          <el-radio-group v-model="addForm.SceneCollectionSwitch">
            <el-radio :label="0">关闭</el-radio>
            <el-radio :label="1">开启</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item label="TP评分引导开关: " prop="trustPilotRateGuidingSwitch">
          <el-radio-group v-model="addForm.TrustPilotRateGuidingSwitch">
            <el-radio :label="0">关闭</el-radio>
            <el-radio :label="1">开启</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item label="新手引导开关: " prop="newUserGuidingSwitch">
          <el-radio-group v-model="addForm.NewUserGuidingSwitch">
            <el-radio :label="0">关闭</el-radio>
            <el-radio :label="1">开启</el-radio>
            <el-radio :label="2">开且不可关闭</el-radio>
          </el-radio-group>
        </el-form-item>   
        <el-form-item label="竞品信息收集: " prop="RsSwitch">
          <el-radio-group v-model="addForm.RsSwitch">
            <el-radio :label="0">关闭</el-radio>
            <el-radio :label="1">开启</el-radio>
          </el-radio-group>
          <el-input
            type="textarea"
            :rows="2"
            placeholder="请输入竞品软件名称，如：easeus,diskdrill"
            v-model="addForm.RsList">
          </el-input>
        </el-form-item>    
             <el-form-item label="Filetype开关: " prop="fileTypeSwitch">
          <el-radio-group v-model="addForm.FileTypeSwitch">
            <el-radio :label="0">关闭</el-radio>
            <el-radio :label="1">开启</el-radio>
          </el-radio-group>
        </el-form-item>  
      </el-form>

      <!--底部区域-->
      <div slot="footer" class="dialog-footer">
        <el-button @click="dialogFormVisible = false" :disabled = "loading">取 消</el-button>
        <el-button type="primary" @click="onAddProductCfg" :disabled = "loading">确 定</el-button>
      </div>
    </el-dialog>

  </div>
</template>

<script>

import {getProductCfg, addProductCfg, delProductCfg} from "@/api/productcfg";
import UploadExcelComp from "@/components/common/UploadExcel";
import {formatDate} from "@/utils/date"

export default {
  name: "Recoverit",
  components: {
    UploadExcelComp
  },
  data() {
    return {
      form: {
        name: ''
      },
      title: "",     // 弹出层标题
      pid: null,     // 用户PID
      loading: true, // 表单数据加载中
      data: [],      // 产品配置列表
      page: 1,       // 当前页码
      pageSize: 20,  // 每页大小
      dialogFormVisible: false, // 控制对话框的显示隐藏
      operType: false, // 操作类型
      // 添加配置信息
      addForm:{
        newPid: null,  // 产品PID
        previewLimit: 0, // 预览限制
        maxSize: 500,  // 预览文件大小
        size: 30,  // 免费额度
        FirstMonthFreeSize: 500,  // Free版本首月免费Size
        MonthlyFreeSize: 200,  // Free版本每月免费Size
        previewMaskSwitch: 0, // 预览蒙版开关
        usageScenarioSwitch: 0, // 场景收集开关
        SDCardGuidingAdvancedRecoverySwitch: 0, // SD卡引导高级恢复
        logSwitch: 0,  // 日志开关
        discountSwitch: 0, // 折扣开关
        WeekDiscountSwitch: 0, // 周折扣开关
        CompetitorDiscountSwitch: 0, // 竞品折扣开关
        NonCompetitorDiscountSwitch: 0, // 非竞品折扣开关,
        FileTypeSwitch: 0, // 文件类型开关
        BuySwitch: 0, //购买弹窗
        RsSwitch: 0, // 收集竞品信息开关
        RsList: '', // 收集竞品信息列表
        MonResidentSwitch: 0, //监控常驻开关
        SceneCollectionSwitch: 0, //用户场景收集开关
      },
      addFormRules: {
        newPid: [{required: true, trigger: 'blur', message: "PID不能为空"}],
        previewLimit:[{required: true, trigger: 'blur', message: "PreviewLimit不能为空"}],
        maxSize:[{required: true, trigger: 'blur', message: "MaxSize不能为空"}]
      }
    }
  },
  created() {
    this.loadProductCfgInfo()
  },
  methods: {
    // 表单重置
    reset() {
      this.addForm = {
        newPid: null,  // 产品PID
        previewLimit: 0, // 预览限制
        maxSize: 500,  // 预览文件大小
        previewMaskSwitch: 0, // 预览蒙版开关
        size: 30,  // 免费额度
        FirstMonthFreeSize: 500,  // Free版本首月免费Size
        MonthlyFreeSize: 200,  // Free版本每月免费Size
        usageScenarioSwitch: 0, // 场景收集开关
        SDCardGuidingAdvancedRecoverySwitch: 0, // SD卡引导高级恢复
        NewUserGuidingSwitch: 0, // 新用户引导开关
        TrustPilotRateGuidingSwitch: 0, // 信任评分引导开关
        logSwitch: 0,  // 日志开关
        discountSwitch: 0, // 折扣开关
        WeekDiscountSwitch: 0, // 周折扣开关
        CompetitorDiscountSwitch: 0, // 竞品折扣开关
        NonCompetitorDiscountSwitch: 0, // 非竞品折扣开关
         FileTypeSwitch: 0, // 文件类型开关
        BuySwitch: 0, //购买弹窗
        RsSwitch: 0, // 收集竞品信息开关
        RsList: '', // 收集竞品信息列表
        MonResidentSwitch: 0, //监控常驻开关
        SceneCollectionSwitch: 0, //用户场景收集开关
      }
    },
    loadProductCfgInfo(page = 1) {
      this.loading = true
      getProductCfg({
        "product_type": 0,
        page,
        "count": this.pageSize,
        "pid": this.pid!= null && this.pid != "" ? Number(this.pid) : null
      }).then(res => {
        this.data = res.data
        //  关闭加载中
        this.loading = false
      }).catch(err => {
        this.loading = false
      })
    },
    /**
     *  分页按钮响应交互
     */
    onCurrentChange(page) {
      this.loadProductCfgInfo(page)
    },
    /**
     *  根据条件查询
     */
    onSearch() {
      this.loadProductCfgInfo()
    },
    /**
     *  新增产品配置信息
     */
    onHandleAdd() {
      this.reset()
      this.operType = false
      this.dialogFormVisible = true
      this.title = "添加产品配置信息"
    },
    /**
     * 删除用户评价信息
     */
    onDeleteProductCfg(pid) {
      this.$confirm('确定删除吗？', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        delProductCfg(pid).then(res => {
          //  删除成功
          this.loadProductCfgInfo(this.page)
        })
      }).catch(() => {});
    },
    /**
     *  修改产品配置信息
     */
    onEditProductCfg(data) {
      this.reset()
      this.operType = true
      this.dialogFormVisible = true
      this.addForm.newPid = data.Pid
      this.addForm.previewLimit = data.preview.PreviewLimit
      this.addForm.maxSize = data.preview.MaxSize
      this.addForm.logSwitch = data.preview.LogSwitch      
      this.addForm.PreviewMaskSwitch = data.preview.PreviewMaskSwitch
      this.addForm.size = data.free_limit.Size
      this.addForm.FirstMonthFreeSize = data.properties.FirstMonthFreeSize
      this.addForm.MonthlyFreeSize = data.properties.MonthlyFreeSize
      this.addForm.usageScenarioSwitch = data.properties.UsageScenarioSwitch
      this.addForm.discountSwitch = data.properties.DiscountSwitch
      this.addForm.FileTypeSwitch = data.properties.FileTypeSwitch
      this.addForm.WeekDiscountSwitch = data.properties.WeekDiscountSwitch
      this.addForm.CompetitorDiscountSwitch = data.properties.CompetitorDiscountSwitch
      this.addForm.NonCompetitorDiscountSwitch = data.properties.NonCompetitorDiscountSwitch
      this.addForm.BuySwitch = data.properties.BuySwitch
      this.addForm.MonResidentSwitch = data.properties.MonResidentSwitch
      this.addForm.SceneCollectionSwitch = data.properties.SceneCollectionSwitch
      this.addForm.SDCardGuidingAdvancedRecoverySwitch = data.properties.SDCardGuidingAdvancedRecoverySwitch
      this.addForm.NewUserGuidingSwitch = data.properties.NewUserGuidingSwitch
      this.addForm.TrustPilotRateGuidingSwitch = data.properties.TrustPilotRateGuidingSwitch
      this.addForm.RsSwitch = data.software.RsSwitch || 0
      this.addForm.RsList = data.software.RsList || ''
      this.addForm.FileTypeSwitch = data.properties.FileTypeSwitch 
      this.title = "修改产品配置信息"
    },
    /**
     * 添加产品配置信息
     */
    onAddProductCfg() {
      this.$refs["addFormRef"].validate(valid => {
        if (valid) {
          this.loading = true
          addProductCfg({
            "product_type": 0,
            "data": {
              "Pid": parseInt(this.addForm.newPid),
              "preview": {
                "PreviewLimit": this.addForm.previewLimit,
                "MaxSize": this.addForm.maxSize,
                "PreviewMaskSwitch": this.addForm.previewMaskSwitch,
                "LogSwitch": this.addForm.logSwitch
              },
              "properties": {
                    "DiscountSwitch": this.addForm.discountSwitch,
                    "WeekDiscountSwitch": this.addForm.WeekDiscountSwitch,
                    "CompetitorDiscountSwitch": this.addForm.CompetitorDiscountSwitch,
                    "NonCompetitorDiscountSwitch": this.addForm.NonCompetitorDiscountSwitch,
                    "BuySwitch": this.addForm.BuySwitch,
                    "MonResidentSwitch": this.addForm.MonResidentSwitch,
                    "SceneCollectionSwitch": this.addForm.SceneCollectionSwitch,
                    "UsageScenarioSwitch": this.addForm.usageScenarioSwitch,
                    "SDCardGuidingAdvancedRecoverySwitch": this.addForm.SDCardGuidingAdvancedRecoverySwitch || "0",    
                    "NewUserGuidingSwitch": this.addForm.NewUserGuidingSwitch,
                    "TrustPilotRateGuidingSwitch": this.addForm.TrustPilotRateGuidingSwitch,
                    "FirstMonthFreeSize": this.addForm.FirstMonthFreeSize,
                    "MonthlyFreeSize": this.addForm.MonthlyFreeSize,
                    "FileTypeSwitch":this.addForm.FileTypeSwitch
              },
              "free_limit": {
                "Size": this.addForm.size || "30"
              },
              "software": {
                    "RsSwitch": this.addForm.RsSwitch,
                    "RsList": this.addForm.RsList
              }
            }

          }).then(res => {
            //  添加成功，刷新列表
            this.loadProductCfgInfo(this.page)
            this.dialogFormVisible = false
            this.loading = false
            this.$message({
              message: `恭喜您，${ this.operType ? '修改' : '添加'}成功`,
              type: 'success'
            });
          }).catch(err => {
            this.loading = false
            this.$message({
              message: `不好意思，${ this.operType ? '修改' : '添加'}失败了哟，请重试`,
              type: 'warning'
            });
          })
        }
      })
    },
    beforeUpload(file) {   //导入
      var a = file
      const isLt1M = file.size / 1024 / 1024 < 1
      if (isLt1M) {
        return true
      }
      this.$message({
        message: '请不要上传大于1m的文件!',
        type: 'warning'
      })
      return false
    },

    handleSuccess({ results, header }) {  //导入内容
      if (header[1] != "Pid" || header[2] != "PreviewLimit" || header[3] != "MaxSize" || header[4] != "LogSwitch" || header[5] != "DiscountSwitch" || header[6] != "WeekDiscountSwitch"
       || header[7] != "RsSwitch" || header[8] != "RsList" || header[9] != "MonResidentSwitch" || header[10] != "SceneCollectionSwitch")  {
        this.$message({
          message: '参数列表错误，请按对应格式进行导入【表头：Pid\tPreviewLimit\tMaxSize\tLogSwitch\tDiscountSwitch\tWeekDiscountSwitch\tRsSwitch\tRsList\tMonResidentSwitch\tSceneCollectionSwitch】',
          type: 'warning'
        })
        return
      }
      var res = new Array();

      results.forEach((item,index,results)=> {
        res[index] = {
          "product_type": 0,
          "data": {
            "Pid": results[index].Pid,
            "preview": {
              "PreviewLimit": results[index].PreviewLimit,
              "MaxSize": results[index].MaxSize,
              "LogSwitch": results[index].LogSwitch
            },
            "properties": {
              "DiscountSwitch": results[index].DiscountSwitch,
              "WeekDiscountSwitch": results[index].WeekDiscountSwitch,
              "CompetitorDiscountSwitch": results[index].CompetitorDiscountSwitch,
              "NonCompetitorDiscountSwitch": results[index].NonCompetitorDiscountSwitch,
              "MonResidentSwitch": results[index].MonResidentSwitch,
              "SceneCollectionSwitch": results[index].SceneCollectionSwitch,
              "UsageScenarioSwitch": results[index].UsageScenarioSwitch,
              "SDCardGuidingAdvancedRecoverySwitch": results[index].SDCardGuidingAdvancedRecoverySwitch || "0",
              "NewUserGuidingSwitch": results[index].NewUserGuidingSwitch,
              "TrustPilotRateGuidingSwitch": results[index].TrustPilotRateGuidingSwitch,
              "FirstMonthFreeSize": results[index].FirstMonthFreeSize,
              "MonthlyFreeSize": results[index].MonthlyFreeSize,
              "FileTypeSwitch":results[index].FileTypeSwitch
            },
            "free_limit": {
              "Size": results[index].size,
            },
            "software": {
              "RsSwitch": results[index].RsSwitch,
              "RsList": results[index].RsList
            }
          }
        }
      })
      var params = {
        "datas": res
      }
      this.loading = true
      addProductCfg(params).then(response => {
        //  添加成功，刷新列表
        this.loadProductCfgInfo(this.page)
        this.loading = false
        this.$message({
          type: 'success',
          message: '添加成功!'
        });
      })
    },
    //为空转换
    formatterEmpty(row, column, v){
      if (v || (v === 0)) return v
      return "-"
    },
    //时间转换
    formatterDateTime(row, column, v){
      if (!v) return "-"
      if(String(v).length === 10) v = v * 1000
      let date = new Date(v)
      return formatDate(date, 'yyyy-MM-dd hh:mm:ss')
    }  
  }
}
</script>

<style scoped>

.list-table {
  margin-bottom: 10px;
}

.btn {
  display: flex;
}

.preview-file .el-input{
  width: 120px;
}

.free-limit .el-input{
  width: 120px;
}

</style>
<style>
.recoverit-product{
  margin-top: 5vh!important;
}
</style>
